body {
	background-color: #ffffff;
}
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
	border-radius: 7px;
	cursor: pointer;
}
::-webkit-scrollbar-track {
	background: #f0f2f5;
	border-radius: 5px;
}
::-webkit-scrollbar-thumb {
	background: #d0d7de;
	border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
	background: #b4bfcb;
	border-radius: 5px;
}

.cas-row,
.cas-cell {
	border-bottom: none !important;
	padding: 0px 10px 0px 10px !important;
}

.reporting-period-width {
	font-size: 13px !important;
}
